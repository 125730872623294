import React from 'react'
import styled from 'styled-components'

import Container from '../Container'

interface PageHeaderProps {
  icon: React.ReactNode
  subtitle?: string
  note?: string
  title?: string
}

const PageHeader: React.FC<PageHeaderProps> = ({ icon, subtitle, note, title }) => {
  return (
    <Container size="md">
      <StyledPageHeader>
          <HomeIconList>{icon}</HomeIconList>
        <StyledTitle>{title}</StyledTitle>
        <StyledSubtitle>{subtitle}<br/>{note}</StyledSubtitle>
      </StyledPageHeader>
    </Container>
  )
}

const StyledPageHeader = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-bottom: ${(props) => props.theme.spacing[6]}px;
  padding-top: ${(props) => props.theme.spacing[6]}px;
  margin: 0 auto;
`

const HomeIconList = styled.div`
  text-align : center;
`

const StyledTitle = styled.h1`
  font-family: 'Russo One', sans-serif;
  color: ${(props) => props.theme.color.white};
  font-size: 55px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
`

const StyledSubtitle = styled.h3`
  color: ${(props) => props.theme.color.white};
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  padding: 0;
`

export default PageHeader
