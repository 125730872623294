import React from 'react'
import icon from '../../assets/img/D502logo.png'

interface D502IconProps {
  size?: number
}

const D502Icon: React.FC<D502IconProps> = ({ size = 50}) => (
    <img
        role="img"
        src={icon}
        width={size}
        height={size}
    >
    </img>
)

export default D502Icon
