import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const GAS_LIMIT = {
  STAKING: {
    DEFAULT: 200000,
    SNX: 850000,
  },
}

export const getMasterChefAddress = (sushi) => {
  return sushi && sushi.masterChefAddress
}
export const getSushiAddress = (sushi) => {
  return sushi && sushi.sushiAddress
}
export const getWethContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.weth
}
export const getUsdtContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.usdt
}
export const getUsdcContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.usdc
}

export const getMasterChefContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.masterChef
}
export const getSushiContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.sushi
}

export const getFarms = (sushi) => {
  return sushi
    ? sushi.contracts.pools.map(
        ({
          pid,
          name,
          symbol,
           pairIcon0,
           pairIcon1,
          tokenAddress,
          tokenSymbol,
          tokenContract,
          lpAddress,
          lpContract,
        }) => ({
          pid,
          id: symbol,
          name,
          lpToken: symbol,
          lpTokenAddress: lpAddress,
          lpContract,
          tokenAddress,
          tokenSymbol,
          tokenContract,
          earnToken: 'd502',
          earnTokenAddress: sushi.contracts.sushi.options.address,
          pairIcon0,
          pairIcon1,
        }),
      )
    : []
}

export const getPoolWeight = async (masterChefContract, pid) => {
  const { allocPoint } = await masterChefContract.methods.poolInfo(pid).call()
  const totalAllocPoint = await masterChefContract.methods
    .totalAllocPoint()
    .call()
  return new BigNumber(allocPoint).div(new BigNumber(totalAllocPoint))
}

export const getEarned = async (masterChefContract, pid, account) => {
  return masterChefContract.methods.pendingD502(pid, account).call()
}

export const getTotalLPWethValue = async (
    masterChefContract,
    wethContract,
    lpContract,
    tokenContract,
    pid,
) => {
  // Get balance of the token address
  const tokenAmountWholeLP = await tokenContract.methods
      .balanceOf(lpContract.options.address)
      .call()
  const tokenDecimals = await tokenContract.methods.decimals().call()

  // Get the share of lpContract that masterChefContract owns
  const balance = await lpContract.methods
      .balanceOf(masterChefContract.options.address)
      .call()

  // Convert that into the portion of total lpContract = p1
  const totalSupply = await lpContract.methods.totalSupply().call()

  // Get total weth value for the lpContract = w1
  const lpContractWeth = await wethContract.methods
      .balanceOf(lpContract.options.address)
      .call()

  // Return p1 * w1 * 2
  const portionLp = new BigNumber(balance).div(new BigNumber(totalSupply))
  const lpWethWorth = new BigNumber(lpContractWeth)
  const totalLpWethValue = portionLp.times(lpWethWorth).times(new BigNumber(2))

  // Calculate
  const tokenAmount = new BigNumber(tokenAmountWholeLP)
      .times(portionLp)
      .div(new BigNumber(10).pow(tokenDecimals))

  const wethAmount = new BigNumber(lpContractWeth)
      .times(portionLp)
      .div(new BigNumber(10).pow(18))

  return {
    tokenAmount,
    wethAmount,
    totalWethValue: totalLpWethValue.div(new BigNumber(10).pow(18)),
    tokenPriceInWeth: wethAmount.div(tokenAmount),
    poolWeight: await getPoolWeight(masterChefContract, pid),
  }
}

export const getTotalLPWethValueFromUSDT = async (
    masterChefContract,
    wethContract,
    usdtContract,
    usdtLpContract,
    lpContract,
    tokenContract,
    pid,
) => {
  // Get balance of the token address
  const tokenAmountWholeLP = await tokenContract.methods.balanceOf(lpContract.options.address).call()
  const tokenDecimals = await tokenContract.methods.decimals().call()

  // Get the share of lpContract that masterChefContract owns
  const balance = await lpContract.methods.balanceOf(masterChefContract.options.address).call()

  // Convert that into the portion of total lpContract = p1
  const totalSupply = await lpContract.methods.totalSupply().call()

  //Get total weth value for the lpContract = w1
  //usdt -> weth
  const usdtLpWeth = await wethContract.methods.balanceOf(usdtLpContract.lpTokenAddress).call();
  const usdtLpUsdt = await usdtContract.methods.balanceOf(usdtLpContract.lpTokenAddress).call();
  const usdtWethPrice = new BigNumber(usdtLpWeth).div(new BigNumber(usdtLpUsdt))
  const lpUsdt = await usdtContract.methods.balanceOf(lpContract.options.address).call();
  const lpContractWeth = new BigNumber(lpUsdt).times(usdtWethPrice)

  // Return p1 * w1 * 2
  const portionLp = new BigNumber(balance).div(new BigNumber(totalSupply))
  const lpWethWorth = new BigNumber(lpContractWeth)
  const totalLpWethValue = portionLp.times(lpWethWorth).times(new BigNumber(2))

  // Calculate
  const tokenAmount = new BigNumber(tokenAmountWholeLP).times(portionLp).div(new BigNumber(10).pow(tokenDecimals))
  const wethAmount = new BigNumber(lpContractWeth).times(portionLp).div(new BigNumber(10).pow(18))

  return {
    tokenAmount,
    wethAmount,
    totalWethValue: totalLpWethValue.div(new BigNumber(10).pow(18)),
    tokenPriceInWeth: wethAmount.div(tokenAmount),
    poolWeight: await getPoolWeight(masterChefContract, pid),
  }
}

export const getTotalLPWethValueFromUSDC = async (
    masterChefContract,
    wethContract,
    usdtContract,
    usdcContract,
    usdtLpContract,
    lpContract,
    tokenContract,
    pid,
) => {
  // Get balance of the token address
  const tokenAmountWholeLP = await tokenContract.methods.balanceOf(lpContract.options.address).call()
  const tokenDecimals = await tokenContract.methods.decimals().call()

  // Get the share of lpContract that masterChefContract owns
  const balance = await lpContract.methods.balanceOf(masterChefContract.options.address).call()

  // Convert that into the portion of total lpContract = p1
  const totalSupply = await lpContract.methods.totalSupply().call()

  //Get total weth value for the lpContract = w1
  //USDCとUSDT が同じレートの前提
  const usdtLpWeth = await wethContract.methods.balanceOf(usdtLpContract.lpTokenAddress).call();
  const usdtLpUsdt = await usdtContract.methods.balanceOf(usdtLpContract.lpTokenAddress).call();

  //USDT-ETH のレート計算
  const usdtWethPrice = new BigNumber(usdtLpWeth).div(new BigNumber(usdtLpUsdt))

  //プールに預けられているUSDC枚数
  const lpUsdc = await usdcContract.methods.balanceOf(lpContract.options.address).call();

  //USDC枚数とUSDT-ETHのレートから、プールに預けられているUSDCのETH換算の枚数を計算
  const lpContractWeth = new BigNumber(lpUsdc).times(usdtWethPrice)

  // Return p1 * w1 * 2
  const portionLp = new BigNumber(balance).div(new BigNumber(totalSupply))
  const lpWethWorth = new BigNumber(lpContractWeth)
  const totalLpWethValue = portionLp.times(lpWethWorth).times(new BigNumber(2))

  // Calculate
  const tokenAmount = new BigNumber(tokenAmountWholeLP).times(portionLp).div(new BigNumber(10).pow(tokenDecimals))
  const wethAmount = new BigNumber(lpContractWeth).times(portionLp).div(new BigNumber(10).pow(18))

  return {
    tokenAmount,
    wethAmount,
    totalWethValue: totalLpWethValue.div(new BigNumber(10).pow(18)),
    tokenPriceInWeth: wethAmount.div(tokenAmount),
    poolWeight: await getPoolWeight(masterChefContract, pid),
  }
}

export const approve = async (lpContract, masterChefContract, account) => {
  return lpContract.methods
    .approve(masterChefContract.options.address, ethers.constants.MaxUint256)
    .send({ from: account })
}

export const getSushiSupply = async (sushi) => {
  return new BigNumber(await sushi.contracts.sushi.methods.totalSupply().call())
}

export const stake = async (masterChefContract, pid, amount, account) => {
  amount = !amount ? '0' : amount ;
  return masterChefContract.methods
    .deposit(
      pid,
      new BigNumber(amount).times(new BigNumber(10).pow(18)).toString(),
    )
    .send({ from: account })
    .on('transactionHash', (tx) => {
      console.log(tx)
      return tx.transactionHash
    })
}

export const unstake = async (masterChefContract, pid, amount, account) => {
  amount = !amount ? '0' : amount ;
  return masterChefContract.methods
    .withdraw(
      pid,
      new BigNumber(amount).times(new BigNumber(10).pow(18)).toString(),
    )
    .send({ from: account })
    .on('transactionHash', (tx) => {
      console.log(tx)
      return tx.transactionHash
    })
}
export const harvest = async (masterChefContract, pid, account) => {
  return masterChefContract.methods
    .deposit(pid, '0')
    .send({ from: account })
    .on('transactionHash', (tx) => {
      console.log(tx)
      return tx.transactionHash
    })
}

export const getStaked = async (masterChefContract, pid, account) => {
  try {
    const { amount } = await masterChefContract.methods
      .userInfo(pid, account)
      .call()
    return new BigNumber(amount)
  } catch {
    return new BigNumber(0)
  }
}

export const redeem = async (masterChefContract, account) => {
  let now = new Date().getTime() / 1000
  if (now >= 1597172400) {
    return masterChefContract.methods
      .exit()
      .send({ from: account })
      .on('transactionHash', (tx) => {
        console.log(tx)
        return tx.transactionHash
      })
  } else {
    alert('pool not active')
  }
}
