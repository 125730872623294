export const white = '#FFF'
export const black = '#212121'

export const green = {
  500: '##00d1810',
}

export const red = {
  100: '#FFFDFE',
  200: '#ffb700',
  500: '#595959',
}

export const grey = {
  100: '#f7f4f2',
  200: '#f2f2f2',
  300: '#C5C1C0',
  400: '#5E5E5E',
  500: '#805e49',
  600: '#452b1e',
  800: '#2d1e12',
}
