import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import icon from '../../assets/img/D502logo.png'

const Logo: React.FC = () => {
  return (
    <StyledLogo to="/">
      <img src={icon} height="40" alt="logo"/>
      <StyledText>
          <NumberText>502</NumberText>swap time machine
      </StyledText>
    </StyledLogo>
  )
}

const StyledLogo = styled(Link)`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  text-decoration: none;
`

const StyledText = styled.span`
  color: ${(props) => props.theme.color.white};
  font-family: 'Russo One', sans-serif;
  font-size: 30px;
  letter-spacing: 0.03em;
  @media (max-width: 1000px) {
    display: none;
  }
`

const NumberText = styled.span`
    font-family: Rajdhani;
    font-size: 37px;
    font-weight: bold;
`

export default Logo
