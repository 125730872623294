import BigNumber from 'bignumber.js/bignumber'

export const SUBTRACT_GAS_LIMIT = 100000

const ONE_MINUTE_IN_SECONDS = new BigNumber(60)
const ONE_HOUR_IN_SECONDS = ONE_MINUTE_IN_SECONDS.times(60)
const ONE_DAY_IN_SECONDS = ONE_HOUR_IN_SECONDS.times(24)
const ONE_YEAR_IN_SECONDS = ONE_DAY_IN_SECONDS.times(365)

export const INTEGERS = {
  ONE_MINUTE_IN_SECONDS,
  ONE_HOUR_IN_SECONDS,
  ONE_DAY_IN_SECONDS,
  ONE_YEAR_IN_SECONDS,
  ZERO: new BigNumber(0),
  ONE: new BigNumber(1),
  ONES_31: new BigNumber('4294967295'), // 2**32-1
  ONES_127: new BigNumber('340282366920938463463374607431768211455'), // 2**128-1
  ONES_255: new BigNumber(
    '115792089237316195423570985008687907853269984665640564039457584007913129639935',
  ), // 2**256-1
  INTEREST_RATE_BASE: new BigNumber('1e18'),
}

export const addressMap = {
  uniswapFactoryV2: '0x544Fc21E3BfB051ccE9e6401F6f552e8a1e3d93C',
  YFI: '0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e',
  YCRV: '0xdF5e0e81Dff6FAF3A7e52BA697820c5e32D806A8',
  UNIAmpl: '0xc5be99a02c6857f9eac67bbce58df5572498f40c',
  WETH: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  UNIRouter: '0x544Fc21E3BfB051ccE9e6401F6f552e8a1e3d93C',
}

export const contractAddresses = {
  sushi: {
    1: '0x661B599e21aAA09e3804824f78e99dC469A8876e',
  },
  masterChef: {
    1: '0xC5bC719e972e84fD98E122308A33b8da1dB118EA',
  },
  weth: {
    1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  },
  usdt: {
    1: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  },
  usdc: {
    1: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  },
}

/*
UNI-V2 LP Address on mainnet for reference
==========================================
0  USDT 0x0d4a11d5eeaac28ec3f61d100daf4d40471f1852
1  USDC 0xb4e16d0168e52d35cacd2c6185b44281ec28c9dc
2  DAI  0xa478c2975ab1ea89e8196811f51a7b7ade33eb11
3  sUSD 0xf80758ab42c3b07da84053fd88804bcb6baa4b5c
4  COMP 0xcffdded873554f362ac02f8fb1f02e5ada10516f
5  LEND 0xab3f9bf1d81ddb224a2014e98b238638824bcf20
6  SNX  0x43ae24960e5534731fc831386c07755a2dc33d47
7  UMA  0x88d97d199b9ed37c29d846d00d443de980832a22
8  LINK 0xa2107fa5b38d9bbd2c461d6edf11b11a50f6b974
9  BAND 0xf421c3f2e695c2d4c0765379ccace8ade4a480d9
10 AMPL 0xc5be99a02c6857f9eac67bbce58df5572498f40c
11 YFI  0x2fdbadf3c4d5a8666bc06645b8358ab803996e28
12 SUSHI 0xce84867c3c02b05dc570d0135103d3fb9cc19433
*/

export const supportedPools = [
  {
    pid: 0,
    lpAddresses: {
      1: '0x67DeB004f6644e3AF210e7b28927ceb24A2C7Ce2',
    },
    tokenAddresses: {
      1: '0x2cd9324bA13b77554592d453e6364086FbBa446a',
    },
    name: 'Z502',
    symbol: 'Z502-ETH DLP',
    tokenSymbol: 'Z502',
    pairIcon0: 'https://d502.io/assets/tokens/ico-z.png',
    pairIcon1: 'https://d502.io/assets/tokens/ethereum.png'
  },
  {
    pid: 1,
    lpAddresses: {
      1: '0xF01a22BeB8169c1aF2ca734689b438Ec3005E3B7',
    },
    tokenAddresses: {
      1: '0x661B599e21aAA09e3804824f78e99dC469A8876e',
    },
    name: 'D502',
    symbol: 'D502-ETH DLP',
    tokenSymbol: 'D502',
    pairIcon0: 'https://d502.io/assets/tokens/D502logo.png',
    pairIcon1: 'https://d502.io/assets/tokens/ethereum.png'
  },
  {
    pid: 2,
    lpAddresses: {
      1: '0xc06eAdbF9caC111380026C8E531f2AF9047696e8',
    },
    tokenAddresses: {
      1: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    },
    name: 'USDT',
    symbol: 'USDT-ETH DLP',
    tokenSymbol: 'USDT',
    pairIcon0: 'https://d502.io/assets/tokens/USDT.png',
    pairIcon1: 'https://d502.io/assets/tokens/ethereum.png'
  },
  {
    pid: 3,
    lpAddresses: {
      1: '0x4DfEDC065E65F3419DC21558c780e23D07fa83A5',
    },
    tokenAddresses: {
      1: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    },
    name: 'USDC-USDT',
    symbol: 'USDC-USDT DLP',
    tokenSymbol: 'USDC',
    pairIcon0: 'https://d502.io/assets/tokens/USDC.png',
    pairIcon1: 'https://d502.io/assets/tokens/USDT.png'
  },

  {
    pid: 4,
    lpAddresses: {
      1: '0x0C284B650b6B28093e82450b833903F4E263Aa02',
    },
    tokenAddresses: {
      1: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    },
    name: 'Z502-USDT',
    symbol: 'Z502-USDT DLP',
    tokenSymbol: 'Z502-USDT',
    pairIcon0: 'https://d502.io/assets/tokens/ico-z.png',
    pairIcon1: 'https://d502.io/assets/tokens/USDT.png'
  },
  {
    pid: 5,
    lpAddresses: {
      1: '0xab697D880C9FAA10b0a87A134055915Ef4Efed4C',
    },
    tokenAddresses: {
      1: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    },
    name: 'Z502-USDC',
    symbol: 'Z502-USDC DLP',
    tokenSymbol: 'Z502-USDC',
    pairIcon0: 'https://d502.io/assets/tokens/ico-z.png',
    pairIcon1: 'https://d502.io/assets/tokens/USDC.png'
  },
  {
    pid: 6,
    lpAddresses: {
      1: '0x3e8F3Ff159Ff2BD7c4772d616B236F5E21f2406f',
    },
    tokenAddresses: {
      1: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    },
    name: 'A502-ETH',
    symbol: 'A502-ETH DLP',
    tokenSymbol: 'A502-ETH',
    pairIcon0: 'https://d502.io/assets/tokens/a502lpgobk.png',
    pairIcon1: 'https://d502.io/assets/tokens/ethereum.png'
  },
  {
    pid: 7,
    lpAddresses: {
      1: '0x48e8E9d072d959bAe695d165e8Dd17A8E6e89eb4',
    },
    tokenAddresses: {
      1: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    },
    name: 'A502-USDT',
    symbol: 'A502-USDT DLP',
    tokenSymbol: 'A502-USDT',
    pairIcon0: 'https://d502.io/assets/tokens/a502lpgobk.png',
    pairIcon1: 'https://d502.io/assets/tokens/USDT.png'
  }
]
