import { useCallback, useEffect, useState } from 'react'
import { provider } from 'web3-core'

import BigNumber from 'bignumber.js'
import { useWallet } from 'use-wallet'
import { Contract } from 'web3-eth-contract'

import {
  getMasterChefContract,
  getWethContract,
  getUsdtContract,
  getFarms,
  getTotalLPWethValue,
  getTotalLPWethValueFromUSDT, getUsdcContract, getTotalLPWethValueFromUSDC
} from '../sushi/utils'
import useSushi from './useSushi'
import useBlock from './useBlock'

export interface StakedValue {
  tokenAmount: BigNumber
  wethAmount: BigNumber
  totalWethValue: BigNumber
  tokenPriceInWeth: BigNumber
  poolWeight: BigNumber
}

const useAllStakedValue = () => {
  const [balances, setBalance] = useState([] as Array<StakedValue>)
  const { account }: { account: string; ethereum: provider } = useWallet()
  const sushi = useSushi()
  const farms = getFarms(sushi)
  const masterChefContract = getMasterChefContract(sushi)
  const wethContact = getWethContract(sushi)
  const usdtContact = getUsdtContract(sushi)
  const usdcContact = getUsdcContract(sushi)
  const block = useBlock()

  //USDT
  const usdtLpContract = farms.find(({pid}:{pid: number}) => pid == 2)

  const fetchAllStakedValue = useCallback(async () => {
    const balances: Array<StakedValue> = await Promise.all(
      farms.map(
        ({
          pid,
          lpContract,
          tokenContract,
        }: {
          pid: number
          lpContract: Contract
          tokenContract: Contract
        }) => {
          switch (pid) {
            case 3:
            case 4:
              //USDT
              return getTotalLPWethValueFromUSDT(
                  masterChefContract,
                  wethContact,
                  usdtContact,
                  usdtLpContract,
                  lpContract,
                  tokenContract,
                  pid,
              )
            case 5:
              //USDC
              return getTotalLPWethValueFromUSDC(
                  masterChefContract,
                  wethContact,
                  usdtContact,
                  usdcContact,
                  usdtLpContract,
                  lpContract,
                  tokenContract,
                  pid,
              )
            default:
              return getTotalLPWethValue(
                  masterChefContract,
                  wethContact,
                  lpContract,
                  tokenContract,
                  pid,
              )
          }
        },
      ),
    )

    setBalance(balances)
  }, [account, masterChefContract, sushi])

  useEffect(() => {
    if (account && masterChefContract && sushi) {
      fetchAllStakedValue()
    }
  }, [account, block, masterChefContract, setBalance, sushi])

  return balances
}

export default useAllStakedValue
