import React from 'react'
import styled from 'styled-components'

const Nav: React.FC = () => {
    return (
        <StyledNav>
            <StyledLink
                target="_blank"
                href="https://etherscan.io/address/0xC5bC719e972e84fD98E122308A33b8da1dB118EA"
            >
                TimeMachine Contract
            </StyledLink>
            {/*<StyledLink
                target="_blank"
                href="https://info.uniswap.org/pair/0x271586572365a95ad72902094c91f887e0dfb343"
            >
                Uniswap Z502-ETH
            </StyledLink>*/}
            <StyledLink target="_blank" href="https://twitter.com/Z502token">
                Twitter
            </StyledLink>
            <StyledLink target="_blank" href="https://discord.com/invite/QK2z4ak">
                Discord
            </StyledLink>
            <StyledLink target="_blank" href="https://z502.medium.com/">
                Medium
            </StyledLink>
            {/*<StyledLink target="_blank" href="https://github.com/sushiswap">
                Github
            </StyledLink>*/}
        </StyledNav>
    )
}

const StyledNav = styled.nav`
  align-items: center;
  display: flex;
`

const StyledLink = styled.a`
  color: ${(props) => props.theme.color.grey[300]};
  padding-left: ${(props) => props.theme.spacing[3]}px;
  padding-right: ${(props) => props.theme.spacing[3]}px;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.color.grey[500]};
  }
`

export default Nav
