import React from 'react'
import styled from 'styled-components'

import Nav from './components/Nav'

const Footer: React.FC = () => (
  <div>
      <CautionFooter>
          CAUTION: Please use it at your own risk.
      </CautionFooter>
      <StyledFooter>
          <StyledFooterInner>
              <Nav />
          </StyledFooterInner>
      </StyledFooter>
  </div>
)

const CautionFooter = styled.footer`
    color: ${(props) => props.theme.color.primary.light};
    font-size: 25px;
    align-items: center;
    display: flex;
    justify-content: center;
`
const StyledFooter = styled.footer`
  align-items: center;
  display: flex;
  justify-content: center;
`
const StyledFooterInner = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: ${props => props.theme.topBarSize}px;
  max-width: ${props => props.theme.siteWidth}px;
  width: 100%;
`

export default Footer