import React from 'react'
import styled from "styled-components";

interface CardImageProps {
    pair0src: string,
    pair1src: string,
}

const CardImage: React.FC<CardImageProps> = ({ pair0src, pair1src}) => {
    if (!pair0src || !pair1src)
        return (<StyledCardContainer>
            <img width={75} height={75} src={pair0src} alt=""/>
        </StyledCardContainer>)

    return (<StyledCardContainer>
        <img width={60} height={60} src={pair0src} alt=""/>
        <img width={60} height={60} src={pair1src} alt=""/>
    </StyledCardContainer>)
}

const StyledCardContainer = styled.div`
    margin: 15px;
`

export default CardImage
